<template>
  <div
    class="cursor-pointer text-decoration-underline primary--text"
    @click="showImages"
  >
    Ảnh
  </div>
</template>

<script>
import { api as viewerApi } from "v-viewer";
export default {
  name: "ImageMultipleViewer",
  props: {
    images: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    imageUrls() {
      if (!this.images) {
        return [];
      }
      const images = this.images.split(";");
      return images.map((img) => `${process.env.VUE_APP_FILE_CDN_URL}/${img}`);
    },
  },
  methods: {
    showImages() {
      const index = 0;
      viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index,
        },
        images: this.imageUrls,
      });
    },
  },
};
</script>

<style scoped></style>
